import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
    <Layout cssClasses="page 404">
        <SEO title="404: Not found" />
        <article className={`page type-page 404`}>
            <header className="page-header entry-header">
                <h1 className="page-title entry-title">NOT FOUND</h1>
            </header>

            <div className="page-content">
                <div className="entry-content">
                    <p>
                        You just hit a route that doesn&#39;t exist... the
                        sadness.
                    </p>
                </div>
            </div>
            {/* .entry-content */}
        </article>
        {/* #post-${ID} */}
    </Layout>
)

export default NotFoundPage
